import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Grid from '@bitcoin-portal/bitcoincom-pkg-components/dist/Grid';
import Card from '@bitcoin-portal/bitcoincom-pkg-components/dist/Card';
import H6 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H6';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

const StyledCard = styled(Card)`
  & > div {
    justify-content: space-between;
  }
`;

const Sections = ({ intl: { messages } }) => (
  <Section style={{ paddingTop: 0 }}>
    <ContentBlock>
      <Grid columns={3} columnsMd={2} columnsSm={1}>
        {messages['home.tools'].map(tool => (
          <StyledCard padded key={tool.title}>
            <div style={{ display: 'flex', flexFlow: 'column nowrap'}}>
              <Image
                width="128px"
                height="128px"
                src={tool.image}
                alt={tool['image-alt']}
                wrapperProps={{ style: { paddingBottom: 30, margin: 'auto' } }}
              />
              <H6>{tool.title}</H6>
              <Paragraph>{tool.text}</Paragraph>
            </div>
            <Button href={tool.link} newTab={tool.link.startsWith('https')}>
              {tool.button}
            </Button>
          </StyledCard>
        ))}
      </Grid>
    </ContentBlock>
  </Section>
);

Sections.propTypes = {
  intl: PropTypes.shape({
    messages: PropTypes.shape({
      'home.tools': PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          text: PropTypes.string,
          button: PropTypes.string,
          link: PropTypes.string,
          image: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
};

export default injectIntl(Sections);
