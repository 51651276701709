import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import Newsletter from '@bitcoin-portal/newsletter';
import { Wrapper } from './styled';
import SEO from './SEO';
import Header from './Header';
import Sections from './Sections';

const Home = ({ intl: { messages } }) => (
  <React.Fragment>
    <SEO />
    <Wrapper>
      <Nav />
      <Header />
      <Sections />
      <Newsletter
        showLogo
        fullWidth
        title={messages['home.newsletter.title']}
        subtitle={messages['home.newsletter.subtitle']}
        acList={messages['home.newsletter.acList']}
      />
      <Footer />
    </Wrapper>
  </React.Fragment>
);

Home.propTypes = {
  intl: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    messages: PropTypes.object,
  }).isRequired,
};

export default injectIntl(Home);
